import {useCallback, useContext} from "react";
import {useAuthHeader} from "./hooks.js";
import ConfigContext from "../context/ConfigContext";
import {useSnackbar} from "notistack";
import axios from 'axios';

function useCallDataApi(model) {
    const {unauthenticatedHeaders, headers, formDataHeaders} = useAuthHeader();
    const {baseUrl} = useContext(ConfigContext);
    const {enqueueSnackbar} = useSnackbar();

    const url = baseUrl + model;

    const authenticatedAPI = axios.create({
        baseURL: url,
        headers: headers
    })

    const zipcodeAPI = axios.create({
        baseURL: "https://api.zippopotam.us/",
    })

    const unauthenticatedAPI = axios.create({
        baseURL: url,
        headers: unauthenticatedHeaders
    })

    const formDataAPI = axios.create({
        baseURL: url,
        headers: formDataHeaders
    })


    const createData = useCallback((data) => {
            return authenticatedAPI.post("/", JSON.stringify(data))
                .then(r => r?.data)
                .catch(error => {
                    const resp = error?.response?.data;
                    if (resp) {
                        console.log(resp)
                    } else enqueueSnackbar("Váratlan hiba történt!", {variant: "error"})
                    return null
                })
        },
        [authenticatedAPI]
    );

    const postData = useCallback((slug, data) => {
            return authenticatedAPI.post(slug, JSON.stringify(data))
                .then(r => r?.data)
                .catch(error => {
                    const resp = error?.response?.data;
                    if (resp) {
                        console.log(resp)
                    } else enqueueSnackbar("Váratlan hiba történt!", {variant: "error"})
                    return null
                })
        },
        [authenticatedAPI]
    );

    const getData = useCallback((id = null) => {
            return authenticatedAPI.get(id)
                .then(r => r?.data)
                .catch(error => {
                    const resp = error?.response?.data;
                    if (resp) {
                        console.log(resp)
                    } else enqueueSnackbar("Váratlan hiba történt!", {variant: "error"})
                    return null
                })
        },
        [authenticatedAPI]
    );

    const getDataByZipCode = useCallback((countryCode, zipCode) => {
            return zipcodeAPI.get(`${countryCode}/${zipCode}`)
                .then(r => r?.data)
                .catch(e => {
                    enqueueSnackbar(e?.response?.data?.error ? e.response.data.error : "Hibás Irányítószám", {variant: "error"})
                    return null
                })
        },
        [zipcodeAPI]
    );

    const updateData = useCallback((id, data) => {
            return authenticatedAPI.patch(`${id}/`, JSON.stringify(data))
                .then(r => r?.data)
                .catch(error => {
                    const resp = error?.response?.data;
                    if (resp) {
                        console.log(resp)
                    } else enqueueSnackbar("Váratlan hiba történt!", {variant: "error"})
                    return null
                })
        },
        [authenticatedAPI]
    );

    const deleteData = useCallback((id) => {
            return authenticatedAPI.delete(`${id}/`)
                .then(r => r)
                .catch(error => {
                    const resp = error?.response?.data;
                    if (resp) {
                        console.log(resp)
                    } else enqueueSnackbar("Váratlan hiba történt!", {variant: "error"})
                    return null
                })
        },
        [authenticatedAPI]
    );

    // ************** UNAUTHENTICATED STUFF **************

    const postUnauthData = useCallback((slug, data) => {
            return unauthenticatedAPI.post(slug, JSON.stringify(data))
                .then(r => r?.data)
                .catch(error => {
                    const resp = error?.response?.data;
                    if (resp) {
                        console.log(resp)
                    } else enqueueSnackbar("Váratlan hiba történt!", {variant: "error"})
                    return null
                })
        },
        [unauthenticatedAPI]
    );

    const getUnauthData = useCallback((id = '') => {
            return unauthenticatedAPI.get(id)
                .then(r => r?.data)
                .catch(error => {
                    const resp = error?.response?.data;
                    if (resp) {
                        console.log(resp)
                    } else enqueueSnackbar("Váratlan hiba történt!", {variant: "error"})
                    return null
                })
        },
        [unauthenticatedAPI]
    );

    // ************** FORM DATA STUFF **************

    const formDataCreateData = useCallback((data) => {
            return formDataAPI.post("/", data)
                .then(r => r?.data)
                .catch(error => {
                    const resp = error?.response?.data;
                    if (resp) {
                        console.log(resp)
                    } else enqueueSnackbar("Váratlan hiba történt!", {variant: "error"})
                    return null
                })
        },
        [formDataAPI]
    );

    const formDataUpdateData = useCallback((id, data) => {
            return formDataAPI.patch(`${id}/`, data)
                .then(r => r?.data)
                .catch(error => {
                    const resp = error?.response?.data;
                    if (resp) {
                        console.log(resp)
                    } else enqueueSnackbar("Váratlan hiba történt!", {variant: "error"})
                    return null
                })
        },
        [formDataAPI]
    );

    return {
        getData,
        createData,
        updateData,
        deleteData,
        postData,
        postUnauthData,
        getUnauthData,
        formDataCreateData,
        formDataUpdateData,
        getDataByZipCode,
    };
}

export default useCallDataApi;