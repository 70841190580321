import React, {useState} from 'react';
import { Grid, Button, Typography, Box } from '@mui/material';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
import 'dayjs/locale/hu'; // Import Hungarian locale

dayjs.extend(isBetween);
dayjs.locale('hu'); // Set locale to Hungarian

const Calendar = ({disabledRanges, reference, minDate=null, maxDate=null, disabledColor='lightgrey', reserveColor='#e65a61'}) => {
    const [currentMonth, setCurrentMonth] = useState(dayjs());
    const [selectedRange, setSelectedRange] = useState({start: null, end: null})
    const [hoverDate, setHoverDate] = useState(null);
    const startOfMonth = currentMonth.startOf('month');
    const endOfMonth = currentMonth.endOf('month');
    const startOfWeek = startOfMonth.startOf('week').add(1, 'day'); // Start week on Monday
    const endOfWeek = endOfMonth.endOf('week').add(1, 'day');

    // useEffect(() => {
    //     if (selectedRange?.start && selectedRange.end) handleSelectRange([selectedRange?.start?.toDate(), selectedRange?.end?.toDate()])
    // }, [selectedRange]);

    // useEffect(() => {
    //     handleMonthChange(currentMonth?.toDate())
    // }, [currentMonth])

    const mergeDateRanges = (ranges) => {
        if (ranges.length === 1) return ranges;
        // Parse the dates and sort by start_date
        const sortedRanges = ranges
            .sort((a, b) => a.start_date - b.start_date);

        // Merge ranges
        const mergedRanges = sortedRanges.reduce((merged, current) => {
            const last = merged[merged.length - 1];

            if (last && last.end_date >= current.start_date) {
                // Extend the last range if they overlap or are adjacent
                last.end_date = new Date(Math.max(last.end_date, current.end_date));
            } else {
                // Otherwise, add the current range as is
                merged.push(current);
            }

            return merged;
        }, []);
        // Format the result back to the original structure
        return mergedRanges.map(range => ({
            start_date: range.start_date,
            end_date: range.end_date,
        }));
    };


    const days = [];
    let day = startOfWeek;
    while (day.isBefore(endOfWeek)) {
        days.push(day);
        day = day.add(1, 'day');
    }

    const isDisabled = (date) => {
        if (minDate && date < minDate) return true
        if (maxDate && date > maxDate) return true
        return mergeDateRanges(disabledRanges)?.some((range) => date.isBetween(range.start_date, range.end_date, 'day', '()'));
    }

    const handleDateClick = (date) => {
        if (isDisabled(date)) return;

        if (!selectedRange.start || (selectedRange.start && selectedRange.end)) {
            setSelectedRange({ start: date, end: null });
        } else if (selectedRange.start && !selectedRange.end) {
            if (date.isAfter(selectedRange.start)) {
                // Check if the range contains any disabled dates
                const rangeHasDisabledDates = days.some(
                    (d) => isDisabled(d) && d.isBetween(selectedRange.start, date, 'day', '[]')
                );
                if (!rangeHasDisabledDates) {
                    setSelectedRange({ ...selectedRange, end: date });
                }
            } else {
                // If the date is before the start, swap start and end
                const rangeHasDisabledDates = days.some(
                    (d) => isDisabled(d) && d.isBetween(date, selectedRange.start, 'day', '[]')
                );
                if (!rangeHasDisabledDates) {
                    setSelectedRange({ start: date, end: selectedRange.start });
                }
            }
        }
    };


    const isSelected = (date) => {
        const { start, end } = selectedRange;
        return start && end && date.isBetween(start, end, 'day', '[]');
    };

    const isUnderHovered = (date) => {
        const { start, end } = selectedRange;
        return start && !end && hoverDate && date.isBetween(start, hoverDate, 'day', '[]');
    };

    const getBackgroundStyle = (date) => {
        if (isDisabled(date)) return disabledColor;

        const isPreviousDisabled = isDisabled(date.subtract(1, 'day'));
        const isNextDisabled = isDisabled(date.add(1, 'day'));
        const isSelectionStart = date.isSame(selectedRange.start, 'day');
        const isSelectionEnd = date.isSame(selectedRange.end, 'day');
        const isCurrentlyHovered = date.isSame(hoverDate, 'day');

        const disabledStart = `linear-gradient(to top left, ${disabledColor} 50%, white 50%)`;
        const disabledEnd = `linear-gradient(to bottom right, ${disabledColor} 50%, white 50%)`;

        const disabledEndSelectionStart = `linear-gradient(to bottom right, ${disabledColor} 50%, ${reserveColor} 50%)`;
        const disabledStartSelectionEnd = `linear-gradient(to top left, ${disabledColor} 50%, ${reserveColor} 50%)`;

        const selectionEnd = `linear-gradient(to bottom right, ${reserveColor} 50%, white 50%)`;
        const selectionStart = `linear-gradient(to top left, ${reserveColor} 50%, white 50%)`;

        if (isPreviousDisabled && isNextDisabled) return disabledColor; // Fully gray if surrounded

        if (isPreviousDisabled) {
            if (isSelectionStart) return disabledEndSelectionStart;
            if (isCurrentlyHovered) return disabledEndSelectionStart;
            return disabledEnd;
        }

        if (isNextDisabled) {
            if (isSelectionStart || isSelectionEnd) return disabledStartSelectionEnd;
            if (isCurrentlyHovered) return disabledStartSelectionEnd;
            return disabledStart;
        }

        if (isSelectionStart) {
            if (!selectedRange.end && hoverDate < date) return selectionEnd;
            return selectionStart;
        }

        if (selectedRange.start && !selectedRange.end && isCurrentlyHovered) {
            if (hoverDate >= selectedRange.start) return selectionEnd;
            if (isPreviousDisabled) return disabledEndSelectionStart;
            return selectionStart;
        }

        if (isSelectionEnd) return selectionEnd;
        if (isSelected(date)) return reserveColor;
        if (isUnderHovered(date)) return reserveColor;
        // if (isCurrentlyHovered) return reserveColor;
        return 'white';
    };

    return (
        <Box>
            {/* Header */}
            <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <Button variant='outlined' onClick={() => setCurrentMonth(currentMonth.subtract(1, 'month'))}>Előző</Button>
                <Typography variant="headline2">
                    {currentMonth.format('MMMM YYYY')}
                </Typography>
                <Button variant='outlined' onClick={() => setCurrentMonth(currentMonth.add(1, 'month'))}>Következő</Button>
            </Grid>

            {/* Days of Week */}
            <Grid container>
                {['H', 'K', 'Sze', 'Cs', 'P', 'Szo', 'V'].map((day) => (
                    <Grid item xs={1.7} key={day} textAlign="center">
                        <Typography variant="headline2">{day}</Typography>
                    </Grid>
                ))}
            </Grid>

            {/* Dates */}
            <Grid container>
                {days.map((date) => (
                    <Grid
                        item
                        xs={1.7}
                        key={date.toString()}
                        textAlign="center"
                        onMouseEnter={() => setHoverDate(date)}
                        onClick={() => handleDateClick(date)}
                        sx={{
                            py: 1,
                            height: '80px',
                            background: getBackgroundStyle(date),
                            color: isDisabled(date) ? 'gray' : 'black',
                            cursor: isDisabled(date) ? 'not-allowed' : 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography>{date.date()}</Typography>
                    </Grid>
                ))}
            </Grid>
            <form ref={reference}>
                <input type="hidden" name='start' value={selectedRange?.start?.format('YYYY-MM-DD')}/>
                <input type="hidden" name='end' value={selectedRange?.end?.format('YYYY-MM-DD')}/>
            </form>

        </Box>
    );
};

export default Calendar;